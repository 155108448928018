
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ant-input-affix-wrapper {
  border-radius: 0px !important;
  height: 50px;
}

.ant-collapse-ghost >.ant-collapse-item >.ant-collapse-content >.ant-collapse-content-box {
  padding-block: 0px;
}
.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  align-items: center;
  padding: 0;
}

.ant-collapse {
  font-family: 'Avenir Light' ;
}

.collapse-container {
  max-height: 500px; /* Set a maximum height for the transition effect */
  overflow: hidden;
  transition: max-height 0.3s ease; /* Adjust the duration and easing as needed */
}

.collapse-container.collapsed {
  max-height: 0;
  transition: max-height 0.3s ease; /* Adjust the duration and easing as needed */
}

.react-player__play-icon{
  border-color: transparent transparent transparent white !important;
  border-width: 32px 0px 32px 52px !important;
}
.react-player__preview{
  background-repeat: no-repeat !important;
  background-size: contain !important;
  
  background-color: rgb(71, 209, 195,0.7)!important;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.scrollable-container::-webkit-scrollbar{
  display: none;
}
.video-react .video-react-control-bar {
  display: none;
  background-color: #47d1c3 !important;
}
.video-react .video-react-poster{

  background-color: white !important;
}

.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 50%;
  background: #47d1c3 !important;
  background-color: #47d1c3 !important;
  border: none;
}
.card-container {

  display: flex;

  align-items: center;
  text-align: center;
}
.card-container img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


/* .card-container h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0; /* Remove default margin to ensure accurate centering */
  
 
.hover-card{
  color:black
}

.hover-card:hover{

  color: #47d1c3!important;
  cursor: pointer;

}
.check-tick{
color:black;
}
.check-tick:hover{
  color: white;
}
.active{
  color: #47d1c3!important;

}

.btn-not-completed{
  border:3px solid #47d1c3 !important;
  color: #0a7f73;
border-radius: none !important;
}
.btn-completed{
  border:3px solid #47d1c3 !important;
background: #47d1c3 ;
color: white;
border-radius: none !important;
}

.btn-completed:hover{
  border:3px solid #7be1d7 !important;
background: #7be1d7 ;
color: white;
border-radius: none !important;
}


.btn-not-completed:hover{
  border:3px solid #0a7f73 !important;

  color: #0a7f73;

}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
